<template>
  <v-dialog v-model="dialog" scrollable width="800">
    <v-card v-if="feature" height="600">
      <v-card-text class="pa-0">
        <div :style="displayXS ? 'width: 100%' : 'display: flex; width: 800px; overflow-x: hidden'">
          <div
            :style="displayXS ? 'min-width: 331px;':'flex: 0 0 331px; vertical-align: top; overflow-y: auto; height: calc(600px - 60px);'"
            class="d-inline-block">
            <v-card-actions>
              {{ name || type }}
            </v-card-actions>
            <zws-action-toolbar
              :feature="feature"
            />
            <v-list dense>
              <template v-for="data in arrayValues">
                <v-list-item @click="$refs.htmlViewDialog.open(data)">
                  <v-list-item-avatar>
                    <v-icon>people</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.username }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-for="data in urlValues">
                <v-list-item @click="openLink(data.url)">
                  <v-list-item-avatar>
                    <v-icon>link</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.url }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-for="data in memoValues">
                <v-list-item @click="$refs.htmlViewDialog.open(data)">
                  <v-list-item-avatar>
                    <v-icon>description</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.username }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-for="data in htmlValues">
                <v-list-item @click="$refs.htmlViewDialog.open(data)">
                  <v-list-item-avatar>
                    <v-icon>web</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.username }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-for="data in bloblistValues">
                <v-list-item @click="$refs.photoListDialog.open(data, feature)">
                  <v-list-item-avatar>
                    <v-icon>image</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.username }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-for="data in blobValues">
                <v-list-item @click="$refs.photoDialog.open(data, feature)">
                  <v-list-item-avatar>
                    <v-icon>image</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.username }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>

            <v-list v-if="featureRelatedData" dense subheader>
              <v-subheader v-if="featureRelatedData.issueList.length" inset>Задачи</v-subheader>
              <template v-for="(issue, index) in featureRelatedData.issueList">
                <v-list-item :key="`issue-${index}`" @click="openIssue(issue, $event)">
                  <v-list-item-avatar>
                    <v-avatar :color="getStatus(issue.status).color" class="mx-3" size="20"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ issue.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ getUserFullName(issue.reporter) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                    <span class="blue--text">
                      {{ issue.project.name }}
                    </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-subheader v-if="isAuthenticated && featureRelatedData && featureRelatedData.dataList.length" inset>
                Данные
              </v-subheader>
              <template v-for="(data, index) in featureRelatedData.dataList" v-if="isAuthenticated">
                <v-list-item :key="`data-${index}`" @click="showData(data)">
                  <v-list-item-avatar>
                    <v-icon>{{ getUrlByDataType(data.dataType).icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="blue--text">{{ data.project.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click.native="removeRelatedData(data, $event)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>

            <v-card v-if="isAuthenticated && featureRelatedData" v-show="!featureRelatedData.dataList.length"
                    class="mx-3 my-2"
                    outlined>
              <v-card-text>
                {{ $t('noDataMessage') }}
              </v-card-text>
            </v-card>
            <v-card-actions v-if="isAuthenticated">
              <v-spacer/>
              <v-btn
                v-if="setAbility('MODERATOR')"
                outlined
                @click="$refs.searchDataDialog.open()">
                <v-icon>attach_file</v-icon>
                Связать
              </v-btn>
              <v-btn
                v-if="setAbility('MODERATOR')"
                outlined
                @click="createNewDoc">
                <v-icon>add</v-icon>
                Создать
              </v-btn>
            </v-card-actions>
          </div>

          <div :style="displayXS ? 'width: 100%' : 'width: 469px; height: calc(600px - 60px);'" class="d-inline-block">

            <v-card-actions style="height: 60px; padding: 10px; !important;">
              <v-select
                v-model="query"
                :items="feature.queries"
                :loading="loading"
                dense
                hide-details
                item-text="name"
                return-object
                solo
                @change="makeQuery"/>
              <v-text-field
                v-model="searchField"
                :placeholder="$t('filter')"
                append-icon="mdi-filter"
                class="ml-2"
                dense
                hide-details
                solo/>
            </v-card-actions>

            <div
              id="print"
              :style="displayXS ? '' : 'height: calc(600px - 120px); overflow-y: auto; overflow-x: hidden'" class="pa-3"
            >
              <template
                v-for="prop in filteredSimpleValues">
                <template v-if="!prop.isList">
                  <v-text-field
                    v-if="['integer', 'float'].includes(prop.type)"
                    v-model="prop.value"
                    :label="prop.username"
                    :readonly="prop.isReadonly"
                    dense
                    outlined
                    rows="1"
                    type="number"
                  />
                  <v-textarea
                    v-else
                    v-model="prop.value"
                    :label="prop.username"
                    :readonly="prop.isReadonly"
                    auto-grow
                    dense
                    outlined
                    rows="1"
                  />
                </template>
                <v-select
                  v-if="prop.isList"
                  v-model="prop.value"
                  :items="prop.list"
                  :label="prop.username"
                  :readonly="prop.isReadonly"
                  dense
                  item-text="value"
                  item-value="code"
                  outlined
                />
              </template>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          outlined
          @click="dialog=false">
          {{ $t('button.close') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          outlined
          @click="print"
        >
          {{ $t('print') }}
        </v-btn>
        <v-btn
          v-if="setAbility('MODERATOR')"
          color="primary"
          outlined
          @click="save">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <abstract-data-details-dialog ref="itemDetailsDialog" @save="saveNewDoc"/>
    <search-data-dialog ref="searchDataDialog" @select="addToList"/>
    <html-view-dialog ref="htmlViewDialog"/>
    <photo-list-dialog ref="photoListDialog"/>
    <photo-dialog ref="photoDialog"/>
    <!--    <list-view-dialog ref="listViewDialog"/>-->
  </v-dialog>
</template>

<script>

import messages from '../../../componet-locale/feature-details-dialog/messages'
import SearchDataDialog from '@/components/utils/SearchDataDialog'
import { issueMixin } from '@/mixins/issue-mixin'
import ZwsCommandBuilder from '@/services/zws-command-builder'
import projectMessages from '@/shared-locale/project/messages'
import HtmlViewDialog from '@/components/map/helpers/HtmlViewDialog'
import PhotoListDialog from '@/components/map/helpers/PhotoListDialog'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import AbstractDataDetailsDialog from '@/components/utils/AbstractDataDetailsDialog'
import { EventBus } from '@/event-bus'
import ZwsActionToolbar from '@/components/map/zws/ZwsActionToolbar'
import PhotoDialog from '@/components/map/helpers/PhotoDialog'

export default {
  name: 'FeatureDetailsDialog',
  components: {
    PhotoDialog,
    ZwsActionToolbar,
    AbstractDataDetailsDialog,
    PhotoListDialog,
    HtmlViewDialog,
    SearchDataDialog
  },
  mixins: [issueMixin, displayServiceMixin],
  data: () => ({
    dialog: false,
    loading: false,
    query: null,
    featureRelatedData: null,
    searchField: '',
    feature: null
  }),
  i18n: { messages: messages, sharedMessages: projectMessages },
  computed: {
    name () {
      let nameProperty = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'dname')
      if (nameProperty && nameProperty.value) {
        return nameProperty.value
      }
    },
    type () {
      let type = this.feature.layer.typeList
        .find(type => type.typeId === this.feature.typeId)
      return type.name || 'Object'
    },
    arrayValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (type === 'darray') return true
      })
    },
    urlValues () {
      let list = this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (type === 'durl') return true
      })
      if (list[0] && list[0].value) return JSON.parse(list[0].value)
    },
    memoValues () {
      return this.feature.props.filter(it => it.type === 'memo')
    },
    htmlValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (type === 'dhtml') return true
      })
    },
    blobValues () {
      return this.feature.props.filter(it => it.type === 'blob')
    },
    bloblistValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (type === 'dbloblist') return true
      })
    },
    simpleValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (it.type === 'memo') return false
        if (!['dphoto', 'darray', 'dhtml', 'dbloblist', 'durl'].includes(type)) return true
      })
    },
    filteredSimpleValues () {
      return this.simpleValues
        .filter(item => item.username.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1)
    },
    sys () {
      let sys = this.feature.props.find(feature => feature.name.split('::')[1].toLowerCase() === 'sys')
      if (sys && sys.value) {
        return sys.value
      }
    }
  },
  methods: {
    showData (data) {
      EventBus.$emit('showData', data)
    },
    print () {
      const prtHtml = this.filteredSimpleValues.map(prop => {
        if (prop.isList) {
          let value = ''
          if (prop.value) {
            value = prop.list.find(it => it.code === prop.value).value
          }
          return `<div><b>${prop.username}: ${value}</b></div>`
        } else {
          return `<div><b>${prop.username}: </b>${prop.value}</div>`
        }
      }).join('')

      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
            <style>
                div {padding: 10px}
            </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    async makeQuery () {
      this.loading = true
      let { props } = await ZwsCommandBuilder.getElemsByID(this.feature.layer, this.sys, this.query)
      this.feature.props = props
      this.loading = false
    },
    open (feature) {
      this.feature = { ...feature }
      this.query = this.feature.queries.length
        ? this.feature.queries.find(it => it.name === this.feature.activeQuery) || this.feature.queries[0]
        : null
      this.dialog = true
      this.loadRelatedData()
    },
    loadRelatedData () {
      if (this.feature.props) {
        if (!this.sys || !this.isAuthenticated) return
        this.$axios
          .get('/layer/get-related-data', {
            params: {
              featureSys: this.sys,
              layerId: this.feature.layer.id
            }
          })
          .then(({ data }) => this.featureRelatedData = data)
      }
    },
    createNewDoc () {
      this.$refs.itemDetailsDialog.createData()
    },
    saveNewDoc (doc) {
      let projectId = this.selectedProject.id
      this.$axios
        .post('/docs/save-for-project/' + projectId, doc, { timeout: 120000 })
        .then(response => {
          this.addToList(response.data)
        })
        .catch(reason => {
          console.error(reason.data.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => {
          this.$refs.itemDetailsDialog.hideDialog()
        })
    },
    addToList (item) {
      if (!this.sys) return
      this.$axios
        .get('/layer/bind-data-to-feature', {
          params: {
            abstractDataId: item.id,
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(() => this.loadRelatedData())
    },
    removeRelatedData (item, event) {
      event.stopPropagation()
      if (!this.sys) return
      this.$axios
        .get('/layer/unbind-data-to-feature', {
          params: {
            abstractDataId: item.id,
            featureSys: this.sys,
            layerId: this.feature.layer.id
          }
        })
        .then(() => this.loadRelatedData())
    },
    save () {
      ZwsCommandBuilder.updateElemAttributes(this.feature)
    },
    openLink (url) {
      window.open(url, '_blank').focus()
    }
  }
}
</script>
