<template>
  <v-container>
    <v-app-bar
      v-if="siteInfo"
      fixed
      dark
      app
      color="rgb(46, 47, 53)"
    >
      <img :src="siteInfo ? siteInfo.COMPANY_LOGO : null" style="height: 48px" class="mr-3"/>
      <h3>{{ siteInfo ? siteInfo.COMPANY_NAME : null }}</h3>
      <v-spacer/>
      <v-btn
        v-show="!displayXS"
        text
        class="mr-3"
        to="/registration"
      >
        Создать аккаунт
      </v-btn>
      <v-btn
        v-show="!displayXS"
        color="primary"
        to="/login"
      >
        Войти
      </v-btn>
      <v-btn
        v-show="displayXS"
        icon
        to="/login"
      >
        <v-icon>account_circle</v-icon>
      </v-btn>
    </v-app-bar>

    <div
      v-if="siteInfo ? siteInfo.COMPANY_DESCRIPTION : null"
      v-html="siteInfo ? siteInfo.COMPANY_DESCRIPTION : null"
    />

    <v-card-text class="pa-1 mt-3">
      <v-expansion-panels
        flat
        multiple
        accordion
      >
        <folder-for-categories
          @select="selectCategory"
          v-for="item in categoriesByType"
          :key="item.id"
          :folder="item"
        />
      </v-expansion-panels>

      <v-layout
        class="pa-3"
        row wrap
      >
        <v-flex
          md6 sm12 xl4
          v-for="item in categoriesWithoutType"
          :key="item.id"
        >
          <category-card @select="selectCategory" :item="item"/>
        </v-flex>
      </v-layout>
    </v-card-text>
    <data-list-dialog ref="dataListDlg"/>
  </v-container>
</template>

<script>
import CategoryCard from '@/components/project/CategoryCard.vue'
import FolderForCategories from '@/components/project/FolderForCategories.vue'
import DataListDialog from '@/components/public-data/DataListDialog.vue'
import axios from 'axios'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

export default {
  name: 'PublicHomePage',
  components: { DataListDialog, FolderForCategories, CategoryCard },
  mixins: [displayServiceMixin],
  data: () => ({
    siteInfo: null,
    categoriesWithoutType: [],
    categoriesByType: []
  }),
  created () {
    this.init()
  },
  methods: {
    selectCategory (category) {
      this.$refs.dataListDlg.open(category)
    },
    getCategories () {
      this.$axios
        .get('public-data/categories')
        .then(response => {
          let categories = response.data
          categories
            .forEach(it => {
              it.size = Object
                .values(it.numberOfDataByType)
                .reduce((a, b) => a + b)
            })

          let map = new Map()
          categories
            .forEach(item => {
              if (!map.has(item.category.type)) {
                map.set(item.category.type, [item])
              } else {
                map.get(item.category.type).push(item)
              }
            })

          let others = []
          this.categoriesByType = []

          map.forEach((items, key) => {
            if (!key) {
              others = others.concat(items)
              map.delete(key)
            } else {
              this.categoriesByType.push({
                title: key,
                items: items
                // size: items.reduce((a, b) => a + b.length())
              })
            }
          })
          this.categoriesByType.sort((a, b) => {
            return a.title > b.title ? 1 : -1
          })
          this.categoriesWithoutType = others
          return map
        })
    },
    init () {
      this.getCategories()
      axios
        .get('settings/get-general')
        .then(response => this.siteInfo = response.data)
    }
  }
}
</script>
