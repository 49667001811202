<template>
  <div
    :class="fullScreenMode ? `page-full-screen ${dialogMode ? 'dialog-mode-full-screen' : ''}` : 'view-data-page'"
    :style="{marginLeft: viewDataPageMarginLeft}"
  >
    <div v-if="!displayXS" v-show="panelExpanded" class="navigation-panel">
      <v-card-actions class="pb-0 pt-1">
        <div class="body-1 ml-2 grey--text text--darken-1">
          {{ $t('info') }}
        </div>
        <v-spacer/>
        <v-btn icon @click="panelExpanded = !panelExpanded">
          <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-tabs
        id="doc-nav-bar"
        active-class="active-class"
        center-active
        class="without-background"
        hide-slider
        style="margin-top: 6px; margin-bottom: 9.5px"
      >
        <v-tooltip
          v-for="(tab, i) in tabList"
          v-if="tabList.length > 1" :key="i"
          :disabled="!tab.tooltipText"
          bottom open-delay="150">
          <template v-slot:activator="{ on }">
            <v-tab
              :class="tab.view === selectedView ? 'active-class' : ''"
              @click="selectView(tab.view)"
              v-on="on"
            >
              <v-icon>{{ tab.icon }}</v-icon>
            </v-tab>
          </template>
          <span>{{ tab.tooltipText }}</span>
        </v-tooltip>
        <div v-if="tabList.length === 1" class="px-3 py-2 text-red">
          <v-icon color="blue" left>chat</v-icon>
          {{ $t('comments') }}
        </div>
        <v-spacer/>
        <v-menu :nudge-right="5" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on }">
            <v-tab v-on="on">
              <v-icon>more_vert</v-icon>
            </v-tab>
          </template>
          <v-card>
            <v-list>
              <slot name="actions"/>
              <v-list-item
                v-for="(item, index) in options" v-show="item.visible && setAbility(item.role)"
                :key="index"
                dense
                @click="invoke(item.action)">
                <v-list-item-avatar>
                  <v-icon :color="item.color">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  {{ $t(item.title) }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-tabs>
      <div style="height: calc(100% - 104px); overflow-x: hidden; overflow-y: auto">
        <slot name="navigation"/>

        <div v-show="selectedView === 'comments'" class="pa-2">
          <comments :data="data"/>
        </div>
      </div>

      <abstract-data-details-dialog ref="itemDetailsDialog"/>

      <simple-loader v-model="showLoader" :text="$t('downloading')"/>
    </div>

    <div v-if="!displayXS"
         v-show="!panelExpanded"
         style="cursor: pointer; background: rgb(248, 248, 248); position: fixed; height: 100%; width: 36px; border-right: solid 1px #d6d2d2;"
         @click="panelExpanded = true">
      <v-btn class="mb-2" icon>
        <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
      </v-btn>
      <div
        class="body-1"
        style="padding: 5px; width: 350px; margin-top: -30px; transform-origin: bottom left; transform: rotate(90deg);"
      >
        {{ $t('info') }}
      </div>
    </div>

    <div :style="panelExpanded ? 'margin-left: 280px;' : 'margin-left: 36px;'" class="router-view">
      <v-card-actions v-show="!fullScreenMode" :style="{paddingRight: splitScreenMode ? '50px' : ''}">
        <div class="body-1 ml-2 pr-8 hide-text-overflow">
          <v-breadcrumbs :items="breadcrumbs" class="pa-0"/>
        </div>
        <v-spacer/>

        <slot name="tooltip"/>

        <v-tooltip v-if="!splitScreenMode" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="showInfo" v-on="on">
              <v-icon>
                info
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('info') }}</span>
        </v-tooltip>

        <v-tooltip v-if="!splitScreenMode && !displayXS" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="splitScreen" v-on="on">
              <v-icon>
                flip
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('splitScreen') }}</span>
        </v-tooltip>

        <v-tooltip v-if="!splitScreenMode" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="changeMode" v-on="on">
              <v-icon size="30">
                {{ fullScreenMode ? 'fullscreen_exit' : 'fullscreen' }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ fullScreenMode ? $t('minimizeScreen') : $t('maximizeScreen') }}</span>
        </v-tooltip>

        <v-tooltip v-if="!splitScreenMode && !dialogMode" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="closePage" v-on="on">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('button.close') }}</span>
        </v-tooltip>
      </v-card-actions>

      <div id="doc-frame" class="doc-frame">
        <slot name="doc"/>
      </div>
    </div>

    <div v-if="!splitScreenMode" v-show="fullScreenMode" style="position: fixed; right: 0; top: 0; z-index: 200">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="my-2 mr-2" fab style="float: right" x-small @click="closePage" v-on="on">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.close') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="my-2 mr-2" fab style="float: right" x-small @click="changeMode" v-on="on">
            <v-icon size="22">fullscreen_exit</v-icon>
          </v-btn>
        </template>
        <span>{{ fullScreenMode ? $t('minimizeScreen') : $t('maximizeScreen') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="my-2 mr-2" fab style="float: right" x-small @click="splitScreen" v-on="on">
            <v-icon>flip</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('splitScreen') }}</span>
      </v-tooltip>
    </div>

    <share-data-dialog ref="shareDataDialog"/>
    <confirmation-dialog ref="confirmationDialog" :title="$t('confirmDeletion')"
                         @confirm="confirmRemove">
      <v-btn v-if="setAbility('ADMIN')"
             color="red" outlined
             @click="removeForever">
        {{ $t('remove') }}
      </v-btn>
    </confirmation-dialog>

    <abstract-data-info-dialog ref="infoDialog"/>
  </div>
</template>

<script>
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import FlexibleButton from './FlexibleButton'
import AbstractDataDetailsDialog from './AbstractDataDetailsDialog'
import AbstractDataDetails from './AbstractDataDetails'
import { EventBus } from '@/event-bus'
import TableWidget from '../widget/TableWidget'
import ChartWidget from '../widget/ChartWidget'
import MapWidget from '../widget/MapWidget'
import ShareDataDialog from '@/components/utils/ShareDataDialog'
import Comments from '@/components/utils/Comments'
import messages from '../../componet-locale/view-data-page/messages'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import AbstractDataInfoDialog from '@/components/utils/AbstractDataInfoDialog'
import SimpleLoader from '@/components/utils/SimpleLoader'

export default {
  name: 'ViewDataPage',
  components: {
    AbstractDataInfoDialog,
    ConfirmationDialog,
    Comments,
    ShareDataDialog,
    AbstractDataDetails,
    AbstractDataDetailsDialog,
    FlexibleButton,
    SimpleLoader,
    TableWidget,
    ChartWidget,
    MapWidget
  },
  mixins: [displayServiceMixin],
  i18n: { messages },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    tabList: {
      type: Array,
      default: []
    },
    selectedView: {
      type: String,
      default: ''
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    splitScreenMode: {
      type: Boolean,
      default: false
    },
    dialogMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    view: null,
    panelExpanded: false,
    fullScreenMode: false
  }),
  created () {
    EventBus.$on('closeViewDataPage', () => this.closePage())
  },
  watch: {
    fullScreenMode (val) {
      EventBus.$emit('frameResize', val)
    },
    panelExpanded () {
      EventBus.$emit('frameResize')
    }
  },
  methods: {
    invoke (action) {
      this[action]()
    },
    edit () {
      this.$refs.itemDetailsDialog.editData(this.data)
    },
    share () {
      const origin = window.location.origin
      const projectID = this.selectedProject.id
      const url = `${origin}/project/${projectID}/${this.data.dataType.toLowerCase()}/${this.data.id}`
      this.$refs.shareDataDialog.share(url)
    },
    showInfo () {
      this.$refs.infoDialog.open(this.data.id)
    },
    splitScreen () {
      this.$router.push({
        path: '/split-screen',
        query: { dataId: this.data.id }
      })
    },
    changeMode () {
      this.fullScreenMode = !this.fullScreenMode
    },
    closePage () {
      if (this.splitScreenMode) {
        EventBus.$emit('closeFrame')
      } else if (this.dialogMode) {
        EventBus.$emit('closeAbstractDataView')
      } else {
        EventBus.$emit('closeAbstractDataView')
        let route = this.$route.matched[1]
        this.$router.push({ name: route.name, params: { projectId: this.selectedProject.id } })
      }
    },
    selectView (view) {
      this.$emit('selectView', view)
    },
    confirmRemove () {
      this.$axios
        .get('/data/remove', { params: { id: this.data.id } })
        .then(() => {
          EventBus.$emit('closeViewDataPage')
          EventBus.$emit('loadData')
          this.data.isActive = false
        })
        .catch(error => console.error(error))
    },
    remove () {
      this.$refs.confirmationDialog.open(this.data)
    },
    removeForever () {
      this.$axios
        .get('/data/remove-forever', { params: { id: this.data.id } })
        .then(() => {
          EventBus.$emit('closeViewDataPage')
          EventBus.$emit('loadData')
        })
        .catch(error => console.error(error))
    },
    restore () {
      this.$axios
        .get('/data/restore', { params: { id: this.data.id } })
        .then(() => {
          EventBus.$emit('loadData')
          this.data.isActive = true
        })
        .catch(error => console.error(error))
    }
  },
  computed: {
    breadcrumbs () {
      if (this.data && this.data.id) {
        return [
          {
            text: this.data.project.name,
            disabled: false,
            exact: true,
            to: `/project/${this.data.project.id}/home`
          },
          {
            text: this.getUrlByDataType(this.data.dataType).title,
            disabled: false,
            exact: true,
            to: `/project/${this.data.project.id}/${this.getUrlByDataType(this.data.dataType).api.toLowerCase()}`
          },
          {
            text: this.data.name,
            disabled: false,
            exact: true,
            to: `/project/${this.data.project.id}/${this.getUrlByDataType(this.data.dataType).api.toLowerCase()}/${this.data.id}`
          }
        ]
      } else return []
    },
    options () {
      return [
        {
          title: 'button.edit',
          icon: 'edit',
          color: 'gray',
          action: 'edit',
          role: 'MODERATOR',
          visible: true
        },
        {
          title: 'button.share',
          icon: 'share',
          color: 'gray',
          action: 'share',
          role: 'USER',
          visible: true
        },
        {
          title: 'button.remove',
          icon: 'delete',
          color: 'red',
          action: 'remove',
          role: 'MODERATOR',
          visible: this.data.isActive
        },
        {
          title: 'restore',
          icon: 'restore_from_trash',
          color: 'green',
          action: 'restore',
          role: 'MODERATOR',
          visible: !this.data.isActive
        },
        {
          title: 'remove',
          icon: 'delete_forever',
          color: 'red',
          action: 'removeForever',
          role: 'ADMIN',
          visible: !this.data.isActive
        }
      ]
    },
    viewDataPageMarginLeft () {
      const projectPanelExpanded = this.$store.state.projectPanelExpanded
      if (this.dialogMode) return ''
      if (this.fullScreenMode && projectPanelExpanded) {
        return '280px'
      } else if (this.fullScreenMode && !projectPanelExpanded) {
        return '34px'
      } else {
        return ''
      }
    }
  }
}
</script>
