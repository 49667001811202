var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isAuthenticated)?_c('v-bottom-navigation',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayXS),expression:"displayXS"}],staticClass:"v-bottom-navigation",staticStyle:{"z-index":"120"},attrs:{"app":""}},[_c('v-btn',{attrs:{"to":"/"}},[_c('span',[_vm._v(_vm._s(_vm.$t('projects')))]),_c('v-icon',[_vm._v("apps")])],1),_c('v-btn',{on:{"click":_vm.search}},[_c('span',[_vm._v(_vm._s(_vm.$t('search')))]),_c('v-icon',[_vm._v("search")])],1),_c('v-btn',{on:{"click":_vm.showChats}},[_c('span',[_vm._v(_vm._s(_vm.$t('chats')))]),_c('v-icon',[_vm._v("forum")])],1),_c('v-btn',{attrs:{"to":"/profile"}},[_c('span',[_vm._v(_vm._s(_vm.$t('profile')))]),_c('v-icon',[_vm._v("account_circle")])],1)],1):_vm._e(),(_vm.isAuthenticated)?_c('v-navigation-drawer',{staticClass:"app-navigation elevation-1",attrs:{"app":"","color":"rgb(46, 47, 53)","dark":"","mini-variant":"","mini-variant-width":"48","mobile-break-point":"600"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',[_c('v-list-item',{attrs:{"ripple":false}},[_c('v-menu',{attrs:{"nudge-right":5,"nudge-width":200,"color":"black","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("language")])],1)]}}],null,false,159859386)},[_c('v-list',_vm._l((_vm.languages),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.setLocale(item.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),(_vm.setAbility('SYSTEM_ADMIN'))?_c('v-list-item',{attrs:{"ripple":false,"to":"/admin/users"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("settings")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('administration')))])],1)],1):_vm._e(),_c('v-menu',{attrs:{"nudge-right":5,"nudge-width":200,"color":"black","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isAuthenticated)?_c('v-list-item',_vm._g({staticClass:"mb-2"},on),[_c('user-avatar',{staticClass:"mx-2",attrs:{"user":_vm.currentLoginUser}})],1):_vm._e()]}}],null,false,1323451558)},[_c('v-card',[_c('profile-items')],1)],1)],1)]},proxy:true}],null,false,263156400)},[_c('img',{staticClass:"logo pointer",attrs:{"src":_vm.logo},on:{"click":_vm.goHome}}),_c('v-list',[_c('v-list-item',{attrs:{"ripple":false},on:{"click":_vm.search}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"color":"black","nudge-bottom":"5","nudge-right":"5","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("search")])]}}],null,false,2877689024)},[_c('span',[_vm._v(_vm._s(_vm.$t('search')))])])],1)],1),_c('div',{staticStyle:{"font-size":"9px","margin-top":"-12px","width":"100%","text-align":"center","color":"#cbcbcb","padding-bottom":"10px"}},[_vm._v(" Ctrl + S ")]),_c('v-list-item',{attrs:{"ripple":false},on:{"click":_vm.showChats}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"color":"black","nudge-right":"5","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"content":_vm.totalNumberOfUnreadMessages,"value":_vm.totalNumberOfUnreadMessages,"bottom":"","color":"blue","overlap":"","transition":""}},[_c('v-icon',_vm._g({},on),[_vm._v("forum")])],1)]}}],null,false,1271019089)},[_c('span',[_vm._v(_vm._s(_vm.$t('chats')))])])],1)],1),_c('v-list-item',{attrs:{"ripple":false},on:{"click":_vm.openDrive}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"color":"black","nudge-right":"5","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" cloud ")])]}}],null,false,3601852671)},[_c('span',[_vm._v(_vm._s(_vm.$t('files')))])])],1)],1),_c('v-list-item',{attrs:{"ripple":false},on:{"click":_vm.openKnowledgeBase}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"color":"black","nudge-right":"5","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("assignment")])]}}],null,false,917460573)},[_c('span',[_vm._v(_vm._s(_vm.$t('knowledgeBase')))])])],1)],1),_c('v-divider',{staticClass:"mb-1",staticStyle:{"margin-top":"2px"}}),_c('v-list-item',{staticClass:"py-0 my-0",attrs:{"ripple":false,"dense":"","to":"/"}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"color":"black","nudge-right":"5","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-apps")])]}}],null,false,2953212657)},[_c('span',[_vm._v(_vm._s(_vm.$t('projects')))])])],1)],1),_vm._l((_vm.favoriteProjects),function(project){return _c('v-list-item',{staticClass:"pa-0 mb-2",attrs:{"ripple":false},on:{"click":function($event){return _vm.select(project)}}},[_c('v-tooltip',{attrs:{"color":"black","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"project-avatar",style:(_vm.projectBackground(project.name))},on),[_c('span',_vm._g({staticClass:"white--text"},on),[_vm._v(_vm._s(project.name[0]))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('project'))+" "),_c('b',[_vm._v(_vm._s(project.name))])])])],1)})],2)],1):_vm._e(),_c('global-search',{ref:"searchDialog"}),_c('drive',{ref:"drive"}),_c('article-editor',{ref:"knowledgeBase"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }