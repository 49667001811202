<template>
  <div v-if="selectedProject.id" :class="panelExpanded ? 'project-expanded' : ''">
    <div v-if="projectView" v-show="panelExpanded" id="project-data-bar" class="second-color" style="z-index: 1">
      <v-card-title class="pl-1 py-2" style="height: 49px">
        <div id="header-of-project" class="ml-2">{{ selectedProject.name }}</div>
        <v-spacer/>
        <v-btn v-if="!displayXS" dark icon @click="panelExpanded = !panelExpanded">
          <v-icon dark>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-list class="without-background" dark dense subheader>
        <v-list-item
          v-for="item in mainMenuItems"
          v-if="selectedProject.id && setAbility(item.ROLE)"
          :key="item.title"
          :to="item.link"
        >
          <v-list-item-icon class="mr-3">
            <v-icon color="grey lighten-2">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="grey--text text--lighten-2">{{ $t(item.title) }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mt-2"/>
        <v-subheader>{{ $t('project-data') }}</v-subheader>
        <v-list-item v-for="item in menuItems" v-if="selectedProject.id" :key="item.title" :to="item.link">
          <v-list-item-icon class="mr-3">
            <v-icon color="grey lighten-2">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <span class="grey--text text--lighten-2">{{ $t(item.title) }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="!displayXS"
         v-show="!panelExpanded"
         class="second-color"
         style="cursor: pointer; border-right: solid 1px #d6d2d2; min-height: 100vh; width: 36px; position: fixed; z-index: 1"
         @click="panelExpanded = true">
      <v-btn class="mb-2" dark icon>
        <v-icon>{{ panelExpanded ? 'arrow_left' : 'arrow_right' }}</v-icon>
      </v-btn>
      <div
        class="body-1"
        style="padding: 5px; width: 1000px; overflow: hidden; margin-top: -30px; transform-origin: bottom left; transform: rotate(90deg);"
      >
        {{ selectedProject.name }}
      </div>
    </div>

    <router-view
      :panelExpanded="panelExpanded"
      :style="panelExpanded ? 'padding-left: 280px;' : 'padding-left: 34px;'" class="router-view"
    />
  </div>
</template>

<script>

import { EventBus } from '@/event-bus'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import messages from '../../shared-locale/project/messages'

export default {
  name: 'Project',
  mixins: [displayServiceMixin],
  data: () => ({
    menuItemsList: null
  }),
  i18n: { messages },
  watch: {
    panelExpanded () {
      EventBus.$emit('frameResize')
    }
  },
  async mounted () {
    const items = sessionStorage.getItem('menuItemsList')
    if (items === null) {
      await this.$axios.get('settings/get-general')
        .then(({ data }) => {
          if (data.MENU_ITEMS_LIST && data.MENU_ITEMS_LIST.trim().length > 0) {
            sessionStorage.setItem('menuItemsList', data.MENU_ITEMS_LIST)
            this.menuItemsList = data.MENU_ITEMS_LIST.split(',').map(el => el.trim())
          }
        })
    } else {
      this.menuItemsList = items.split(',').map(el => el.trim())
    }
  },
  computed: {
    projectView () {
      if (!this.displayXS) return true
      return this.$route.name === 'project-view'
    },
    mainMenuItems () {
      return [
        {
          title: 'home',
          icon: 'home',
          link: `/project/${this.selectedProject.id}/home`,
          ROLE: 'USER'
        },
        {
          title: 'dashboard',
          icon: 'dashboard',
          link: `/project/${this.selectedProject.id}/dashboard`,
          ROLE: 'USER'
        },
        {
          title: 'tasks',
          icon: 'style',
          link: `/project/${this.selectedProject.id}/issue`,
          ROLE: 'USER'
        },
        {
          title: 'objects',
          icon: 'place',
          link: `/project/${this.selectedProject.id}/poi-table`,
          ROLE: 'USER'
        },
        {
          title: 'reports',
          icon: 'description',
          link: `/project/${this.selectedProject.id}/report`,
          ROLE: 'USER'
        },
        {
          title: 'changesHistory',
          icon: 'history',
          link: `/project/${this.selectedProject.id}/changes-history`,
          ROLE: 'ADMIN'
        },
      ]
    },
    menuItems () {
      const items = [
        {
          title: 'documents',
          key: 'doc',
          icon: 'book',
          link: `/project/${this.selectedProject.id}/doc`
        },
        {
          title: 'tables',
          key: 'table',
          icon: 'table_chart',
          link: `/project/${this.selectedProject.id}/table`
        },
        {
          title: 'databases',
          key: 'et',
          icon: 'mdi-database',
          link: `/project/${this.selectedProject.id}/database`
        },
        {
          title: 'maps',
          key: 'map',
          icon: 'map',
          link: `/project/${this.selectedProject.id}/map`
        },
        {
          title: 'layers',
          key: 'layer',
          icon: 'layers',
          link: `/project/${this.selectedProject.id}/layer`
        },
        {
          title: 'charts',
          key: 'chart',
          icon: 'insert_chart',
          link: `/project/${this.selectedProject.id}/chart`
        },
        {
          title: 'modbus-units',
          key: 'modbus',
          icon: 'device_hub',
          link: `/project/${this.selectedProject.id}/modbus-unit`
        },
        {
          title: 'calculations',
          key: 'calc',
          icon: 'functions',
          link: `/project/${this.selectedProject.id}/calc`
        }
      ]
      if (this.menuItemsList && this.menuItemsList.length > 0) {
        return items.filter(el => this.menuItemsList.includes(el.key))
      }
      return items
    },
    panelExpanded: {
      get () {
        return this.$store.state.projectPanelExpanded
      },
      set (bool) {
        this.$store.commit('setProjectPanelExpanded', bool)
      }
    }
  }
}
</script>

<style>
#header-of-project {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 48px);
}
</style>
